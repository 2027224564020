/* Default styling for large screens */
.fe-content {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 35px;
  padding: 0px 0px;
}

.features .btn_shadow {
  color: #878e99;
}

.features .heading {
  margin: 60px 0px;
}

.box img {
  width: 100px;
  height: 100px;
}

.features .box h6 {
  text-align: center;
}

/* Media query for tablet screens (max-width: 768px) */
@media (max-width: 768px) {
  .fe-content {
    grid-template-columns: repeat(2, 1fr); /* 4 columns on tablets */
  }
}

/* Media query for small mobile screens (max-width: 391px) */
@media (max-width: 391px) {
  .fe-content {
    grid-template-columns: repeat(1, 1fr); /* 2 columns on mobile */
  }
}
