
.home .righ {
  padding-top: 100px;
  padding-left: 160px;
}

.hero {
  position: relative;
}
.hero h3 {
  font-weight: 100;
  letter-spacing: 2px;
}
.hero h1 {
  font-size: 30px;
  font-weight: bold;
}

.hero h1 span {
  color: #ff014f;
}
.dark-mode .hero h1 span {
  color: #3450A1;
}

.hero h2  {
  color: rgb(40, 3, 3);
  font-size: 50px;
  font-weight: bold;
}

.dark-mode .hero h2 {
  font-size: 50px;
  font-weight: bold;
  color: #3450A1;
}
.hero p {
  color: #878e99;
  font-size: 18px;
  line-height: 30px;
  margin-top: 30px;
  text-align: justify;
  text-justify: inter-word;
}
.button {
  margin-top: 7%;
    display: flex;
    flex-wrap: wrap;
}


.hero_btn h4 {
  font-weight: 500;
  letter-spacing: 2px;
  color: #43474b;
  font-size: 15px;
  margin: 30px 0 30px 0;
}

.hero_btn a {
  margin: 10px  ;
 }

.hero_btn button img {
  width: 20px;
  height: 20px;
}

.hero .right {
  width: 40%;
  position: relative;
}
.hero .right_img {
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 4px 4px 8px #cbcbcb, -4px -4px 8px #ffffff;
  border-radius: 10px;
  height: 580px;
  width: 510px;
  margin-top: 20%;
}
.hero .right_img img {
  position: absolute;
  top: 0;
  right: 10%;
  width: 510px;
}


.div-img-bg {
  padding-bottom: 30px;
  border: 20px solid #a5aaa8;
}
.dark-mode .div-img-bg {
  padding-bottom: 30px;
  border: 20px solid  #3450A1;
}

 .div-img-bg .about-img img {
  width: 100%;
  box-shadow: 0px 0px 85px 0px rgba(0, 0, 0, 0.14);
  margin-top: -60px;
  margin-left: 40px;
  height: 400px;
  object-fit: cover;
}
.btn_shadow{
  flex-direction: column;
 
} 
.location {
  margin-left: 6px;
}

@media (max-width: 768px) {
  /* .div-img-bg {
margin: 0px 180px 0px 190px;
} */
  .hero .f_flex {
    flex-direction: column;
    flex-direction: column-reverse;
  }
   .div-img-bg .about-img img {
    width: 100%;
    box-shadow: 0px 0px 85px 0px rgba(0, 0, 0, 0.14);
    margin-top: -60px;
    margin-left: 45px;
    height: 390px;
    }
    .home .righ {
     padding: 100px 0 0 0;
    }
}

@media (max-width: 391px) {

  .div-img-bg {
    margin: 0px 30px 0px 30px;
    }
   .div-img-bg .about-img img {
    width: 95%;
    box-shadow: 0px 0px 85px 0px rgba(0, 0, 0, 0.14);
    margin-top: -60px;
    margin-left: 45px;
    height: 350px;
  
  }
}
