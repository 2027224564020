.Resume .heading h1 {
  font-size: 30px;
}
.Resume .contents {
  max-width: 70%;
  margin: 0px 100px;
}
.Resume .box {
  padding: 20px;
}
.rate img {
  width: 20px;
  height: 20px;
  color: #7e7eff;
}
.Resume hr {
  margin: 15px 0 15px 0;
  border: 1px solid #e3e9ed;
  background-color: #e3e9ed;
}
.Resume .rate .btn_shadow {
  color: #ff014f;
  padding: 15px 15px;
  font-weight: bold;
  transition: 0.5s;
}
.dark-mode .Resume .rate .btn_shadow {
  color: #ffffff;
}

.Resume p {
  font-size: 14px;
  transition: 0.5s;
  text-align: justify;
}
.Resume .box {
  margin-bottom: 40px;
  position: relative;
}
.Resume .btn_shadow:hover {
  transform: translate(0);
}
.Resume .box:hover .btn_shadow {
  background: #ff014f;
  box-shadow: none;
  color: white;
}
.dark-mode .Resume .box:hover .btn_shadow {
  background: #3450a1;

}
.Resume .box:hover p {
  color: white;
}
.dark-mode .Resume .box:hover p {
  color: #000000;
}
.dark-mode .Resume .box:hover h4{
  color: #000000;
}
.dark-mode .Resume .box:hover h5{
  color: #000000;
}
.Resume .content-section {
  position: relative;
}

.title_content .title h4 {
  color: #000000;
}
.title_content .title h5 {
  color: #000000;
}
.dark-mode .contents .title h4 {
  color: #fff;
}
.dark-mode .contents .title h5 {
  color: #fff;
}

.dark-mode .contents .box p {
  color: #d4d7de;
}

@media (max-width: 768px) {
  .Resume .d_flex {
    flex-direction: column;
  }
  .rate {
    margin-top: 28px;
  }
  .Resume .left::after {
    top: 16%;
    height: 115vh;
    left: 8%;
  }
}

@media (max-width: 391px) {
  .Resume .contents {
    max-width: 110%;
    margin: 0px 40px;
  }
  .Resume .left::after {
    display: none;
  }
  .Resume .box::after {
    width: 23px;
     }
  .dark-mode .Resume .left::after {
    top: 15%;
    left: 5%;
    height: 160vh;
   
  }
}
