/* Blog Section Styling */
.Portfolio.Blog .container {
  padding: 20px;
}

/* Default grid for larger screens */
.Portfolio.Blog .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on large screens */
  gap: 20px;
}

/* Modal Styling */
.modal-blog.modal,
.modal-blog.overlay {
  overflow: scroll;
}

.modal-blog.modal .modal-content {
  position: relative;
  top: 10%;
  left: 0;
  max-width: 70%;
  margin: auto;
  margin-bottom: 10%;
}

.modal-blog .modal-content .left,
.modal-blog .modal-content .right {
  width: 100%;
  padding: 0;
  margin-top: 30px;
}

.publich {
  height: 110px;
}

.publich h4 {
  color: #000000;
}

.detail {
  margin-bottom: 20px;
  margin-top: 20px;
}

.links {
  color: dodgerblue;
}

.dark-mode .links {
  color: #000000;
}

.modal-text span {
  color: #000000;
}

/* Blog Contact Section */
.blog_contact .left,
.blog_contact .right {
  margin: 0 25px 0 0;
}

/* Responsive adjustments */

/* 2 columns on tablets */
@media (max-width: 768px) {
  .Portfolio.Blog .grid {
    grid-template-columns: 2fr !important; /* Force 1 column on mobile */
  }
  .detail{
display: none;
  }
}

/* 1 column on mobile */
@media (max-width: 480px) {
  .Portfolio.Blog .grid {
    grid-template-columns: 1fr !important; /* Force 1 column on mobile */
    display: grid !important;
  }
}
