/* Contact Section Styles */
.Contact {
  /* You can adjust the height if needed */
}

.Contact .left, .Contact .right {
  width: 48%;
}

.Contact .box {
  padding: 15px;
}

.address, .email, .phone {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.map-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56.25%; /* Aspect ratio 16:9 */
}

.map iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}

/* Icon Styling */
.map i {
  font-size: 20px;
  color: #ff014f;
  width: 44px;
  height: 44px;
  background: #f3d2dc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.address:hover i, .email:hover i, .phone:hover i {
  background: #ff014f;
  color: #fff;
}

/* Flexbox and Responsive Adjustments */
.content {
  display: flex;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .Contact .left, .Contact .right {
    width: 100%;
  }
}

@media (max-width: 391px) {
  .Contact .right button span {
    padding-left: 50px;
  }
}
